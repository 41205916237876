import styled from 'styled-components';

const StyledLandingLayout = styled.div`
  .nav-divider {
    color: var(--dark-golden);
  }

  .servicio {
    background-color: #FFF;
    color: var(--dark-blue);

    &-btn {
      background-color: var(--dark-golden);
      border: 1px solid var(--dark-golden);
      text-shadow: 1px 1px 1px #000;
    }
  }

  // Medium devices (tablets, 768px and up)
  @media(min-width: 768px) {
    .search-container {
      background-color: rgba(var(--dark-blue), .5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 2em;
      border-radius: 4px;
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    .search-container {
      top: 20px;
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    .search-container {
      top: 60px;
    }

    .covid-info {
      position: absolute;
      padding: 1em;
      bottom: 100px;
      width:520px;
      left: 60px;
    }
  }

  // HD devices (large desktops, 1900px and up)
  @media (min-width: 1900px) {
    .search-container {
      top: 100px;
    }

    .covid-info {
      position: absolute;
      padding: 3em;
      width: 600px;
      left: 690px;
      font-size: 12pt;
      bottom: 600px
    }
  }
`;

export default StyledLandingLayout;
