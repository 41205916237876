import React from "react";
import Navigation from "../Navigation";
import Footer from "../Footer";
import StyledLandingLayout from "./styles";
// import "./landing.scss";

const LandingLayout = ({ children }) => (
  <StyledLandingLayout>
    <Navigation />
    {children}
    <Footer />
  </StyledLandingLayout>
);

export default LandingLayout;
