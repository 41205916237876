import React, { Component } from "react";
import {
  Container, Row, Col,
} from "reactstrap";

import LandingLayout from "../components/LandingLayout";

class PagosOnlinePage extends Component {
  render() {
    return (
      <LandingLayout>
        <Container>
          <Row className="mt-5">
            <Col>
              <div className="py-5">
                <h1 className="display-5">Pagos Online</h1>
                <p className="lead mt-3">A continuaci&oacute;n ser&aacute; dirigido a la pagina de pagos de PayU, Le recomendamos tener a mano su n&uacute;mero de documento.</p>
                <div className="text-center mt-5">
                  <a
                    className="btn btn-success shadow"
                    style={{ backgroundColor: "#a6c308", border: "#a6c308" }}
                    href="https://checkout.payulatam.com/invoice-collector/#/login/75622"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ir a PayU
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </LandingLayout>
    );
  }
}

export default PagosOnlinePage;
